import router from "@/router";

const user = {
    state: {
        isLoggedIn: !!localStorage.getItem("access_token"),
        user: {},
        bonus: null,
        discount: null,
        ranking: null,
        rankingPunctuality: null,
        payoutsHistory: null,
        checkAgreements: {
            message: "",
            result: null,
        },
        /**
         * For displaying data in two locations:
         * 1 - In popups reminding about bonus payouts a week before the deadline (AppComponent.vue)
         * 2 - For showing notifications in a popup after adding an invoice (UploadDocumentComponent.vue)
         */
        dates:{
            yearToCheckBonus: 2025,
            period1:{
                registerInvoicesFromTo:  'od 01.01.2025&nbsp;r. do 31.10.2025&nbsp;r.',
                displayPopupAboutPayoutFrom: '2025-11-24',
                canPayoutTo: '2025-11-30',
                canPayoutToFriendly: '30.11.2025&nbsp;r.',
            },
            period2:{
                registerInvoicesFromTo:  'od 01.11.2025&nbsp;r. do końca edycji.',
                displayPopupAboutPayoutFrom: '2026-01-25',
                canPayoutTo: '2026-01-31',
                canPayoutToFriendly: '31.01.2026&nbsp;r.',
            },
        },
        deliveries:{
            period1:{
                from: '2025-07-01',
                to:'2025-09-30',
                fromToFriendly:'od 01.07.2025&nbsp;r. do&nbsp;30.09.2025&nbsp;r.',
                fromToFriendlyShort:'01.07.2025&nbsp;r. -&nbsp;30.09.2025&nbsp;r.',
                forOrdersFromTo: 'od 04.02.2025&nbsp;r. do 30.06.2025&nbsp;r.'

            },
            period2:{
                from: '2026-02-01',
                to:'2026-03-31',
                fromToFriendly:'od 01.02.2026&nbsp;r. do&nbsp;31.03.2026&nbsp;r.',
                fromToFriendlyShort:'01.02.2026&nbsp;r. -&nbsp;31.03.2026&nbsp;r.',
                forOrdersFromTo: 'od 01.07.2025&nbsp;r. do 31.01.2026&nbsp;r.'
            }
        }
    },
    mutations: {
        setLoggedIn: function (state, payload) {
            state.isLoggedIn = payload;
        },
        userUpdate: function (state, payload) {
            state.user = { ...state.user, ...payload };
        },
        bonusUpdate: function (state, payload) {
            state.bonus = payload;
        },
        discountUpdate: function (state, payload) {
            state.discount = payload;
        },
        rankingUpdate: function (state, payload) {
            state.ranking = payload;
        },
        rankingPunctualityUpdate: function (state, payload) {
            state.rankingPunctuality = payload;
        },
        payoutsUpdate: function (state, payload) {
            state.payoutsHistory = payload;
        },
        agreementsUpdate: function (state, payload) {
            state.checkAgreements = payload;
        },
    },
    actions: {
        login: function (context, payload) {
            return new Promise((resolve, reject) => {
                window.api
                    .post("/login", payload)
                    .then((response) => {
                        localStorage.setItem(
                            "access_token",
                            response.data.data.token
                        );
                        context.commit("setLoggedIn", true);
                        context.dispatch("getUser");
                        router.go(
                            history && history.length > 2
                                ? -1
                                : { name: "home" }
                        );
                        resolve(response);
                    })
                    .catch((e) => {
                        if (e.response.status === 401) {
                            if (localStorage.getItem("access_token")) {
                                localStorage.removeItem("access_token");
                            }
                        }
                        reject(e);
                    });
            });
        },
        getAgreements: async function (context) {
            await window.api
                .get("my-account/check-agreements")
                .then((response) => {
                    context.commit("agreementsUpdate", {
                        message: response.data.message,
                        result: response.data.data.result,
                    });
                    // if (response.data.data.result === 1) {
                    //     sessionStorage.setItem('agreements_accepted', 'true');
                    // }
                })
                .catch((error) => {
                    context.commit("agreementsUpdate", {
                        message: error.response.data.message,
                        result: error.response.data.data.result,
                    });
                });
        },
        logout: function () {
            localStorage.removeItem('access_token');
            sessionStorage.clear();
            delete window.api.defaults.headers.common["Authorization"];
            router.go({ name: "login" });
        },
        getUser: async function (context) {
            await window.api
                .get("my-account/basic-user-data")
                .then((response) => {
                    context.commit("userUpdate", response.data.data);
                })
                .catch(() => {});
        },
        getUserFull: async function (context) {
            await window.api
                .get("my-account/all-user-data")
                .then((response) => {
                    context.commit("userUpdate", response.data.data);
                })
                .catch(() => {});
        },
        getBonusSummary: function (context) {
            window.api
                .get("my-account/bonus-summary")
                .then((response) => {
                    context.commit("bonusUpdate", response.data.data || {});
                })
                .catch(() => {
                    context.commit("bonusUpdate", {});
                });
        },
        getDiscount: function (context) {
            window.api
                .get("my-account/direct-sales-discount")
                .then((response) => {
                    context.commit("discountUpdate", response.data.data || {});
                })
                .catch(() => {
                    context.commit("discountUpdate", {});
                });
        },
        getRanking: function (context) {
            window.api
                .get("my-account/ranking")
                .then((response) => {
                    context.commit(
                        "rankingUpdate",
                        response.data.data || {}
                    );
                })
                .catch(() => {
                    context.commit("rankingUpdate", {});
                });
        },
        getRankingPunctuality: function (context) {
            window.api
                .get("my-account/ranking-punctuality")
                .then((response) => {
                    context.commit(
                        "rankingPunctualityUpdate",
                        response.data.data || {}
                    );
                })
                .catch(() => {
                    context.commit("rankingPunctualityUpdate", {});
                });
        },
        getBonusPayoutsHistory: function (context) {
            window.api
                .get("my-bonuses/bonus-payout-history")
                .then((response) => {
                    context.commit("payoutsUpdate", response.data.data || []);
                })
                .catch(() => {
                    context.commit("payoutsUpdate", []);
                });
        },
        getPrepaidCard: function (context) {
            window.api
                .get("my-bonuses/prepaid-card")
                .then((response) => {
                    context.commit("userUpdate", response.data.data);
                })
                .catch(() => {});
        },
    },
    getters: {
        isLoggedIn: function (state) {
            return !!state.isLoggedIn;
        },
        user: function (state) {
            return state.user;
        },
        dates: function (state) {
            return state.dates;
        },
        deliveries: function (state) {
            return state.deliveries;
        },
        bonus: function (state) {
            return state.bonus;
        },
        discount: function (state) {
            return state.discount;
        },
        ranking: function (state) {
            return state.ranking;
        },
        rankingPunctuality: function (state) {
            return state.rankingPunctuality;
        },
        payoutsHistory: function (state) {
            return state.payoutsHistory;
        },
        checkAgreements: function (state) {
            return state.checkAgreements;
        },
    },
};

export default user;
